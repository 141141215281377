window._ = require("lodash");

try {
  window.Popper = require("popper.js").default;
  window.$ = window.jQuery = require("jquery");

  require("bootstrap");
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * datatables imports requires separate imports for everything
 * Note: AMD must be disabled in webpack by adding this config:
 * { module: { rules: [{ parser: { amd: false } }] } }
 */
require("datatables.net")(window, $);
require("datatables.net-bs4")(window, $);
require("datatables.net-buttons")(window, $);
require("datatables.net-buttons-bs4")(window, $);
require("datatables.net-buttons/js/buttons.colVis.js")(window, $); // Column visibility
require("datatables.net-buttons/js/buttons.html5.js")(window, $); // HTML 5 file export
require("datatables.net-colreorder")(window, $);
require("datatables.net-colreorder-bs4")(window, $);
require("datatables.net-responsive")(window, $);
require("datatables.net-responsive-bs4")(window, $);
// required to make the excel export button work
window.JSZip = require("jszip");

/**
 * bootstrap switch button
 * https://github.com/gitbrent/bootstrap-switch-button
 */
require("bootstrap-switch-button");

/**
 * jquery typeahead
 * https://github.com/running-coder/jquery-typeahead
 */
require("jquery-typeahead");

