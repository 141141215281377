import * as app from './app.js'

// focus on mileage input box
$(window).on('load', function() {
  $('.page-service #inputServiceType').trigger('focus');
});

/**
 * Datatables
 * after the table is set up, we need to make some adjustments
 * @param settings
 * @param json
 */
function serviceDataTablesInitCompleteFn(settings, json) {

  // make the inputs input-md instead of input-sm so it lines up nicer with the buttons
  $('#gasTable_filter').find('input')
    .addClass('form-control-md').removeClass('form-control-sm');
  $('#gasTable_length').find('select')
    .addClass('form-control-md').removeClass('form-control-sm')
    .addClass('custom-select-md').removeClass('custom-select-sm');

   // hide avg values in the table header
   $('.header-avg').hide();

   // make the inputs input-md instead of input-sm so it lines up nicer with the buttons
   $('#serviceTable_filter').find('input')
    .addClass('form-control-md').removeClass('form-control-sm');
   $('#serviceTable_length').find('select')
    .addClass('form-control-md').removeClass('form-control-sm')
    .addClass('custom-select-md').removeClass('custom-select-sm');

  $('#serviceTable_wrapper').find('.btn-secondary')
    .addClass('btn-outline-secondary').removeClass('btn-secondary');
}

// initialize the gas history datatable
$('#serviceTable').dataTable({
    dom: '<"row" <"col-md-6 col-12"<"float-left"l>><"col-md-6 col-12"<"float-md-right float-left"B><"float-md-right pr-2 float-left"f>>>rt<"row" <"col-md-6" <"float-left"i>><"col-md-6" <"float-right"p>>>',
    lengthMenu: [[10, 25, 100, -1], [10, 25, 100, "All"]],
    pageLength: 10,
    order: [[1, 'desc']], // order by mileage desc
    responsive: true,
    buttons: [
      {
        extend: 'colvis',
        columns: ':not(:first-child)',
        text: '<i class="fas fa-columns"></i>',
        // columnText: dataTablescolVisColumnTextFn
      },
      {
        extend: 'excelHtml5',
        text: '<i class="fas fa-download"></i>'
      },
    ],
    language: {
      search: '',
      searchPlaceholder: 'Filter table',
    },
    orderCellsTop: true,
    initComplete: serviceDataTablesInitCompleteFn,
});
