require('./bootstrap');

// -----------------------------------
// Global Bindings
// -----------------------------------

// disable submit button after submitting form
$('.disable-after-submit').on('click', function(e) {
  e.preventDefault();
  $(this).attr('disabled', true);
  this.closest('form').submit();
});

// hide/show panels and update the icon accordingly
$('.toggle-link').on('click', function(e) {
  const target = $(this).data('target');
  const speed = $(this).data('speed') || '400';
  $('#'+target).slideToggle(speed, function() {
    swapIcons($('#'+target+'CollapseIcon'), 'fa-minus-square', 'fa-plus-square');
  });
});

// enable tooltips
$('[data-toggle="tooltip"]').tooltip()

// manage clicks on clickable-row table rows
var allowClickableRow = true;
$('.clickable-row').on('click', function(e) {
  if (this.getAttribute('location') && allowClickableRow) {
    document.location.href = this.getAttribute('location');
  }
  allowClickableRow = true;
});
// prevent clicks on non-clickable-cells
$('.non-clickable-cell').on('click', function(e) {
  e.preventDefault();
  allowClickableRow = false;
});

// -----------------------------------
// Modals
// -----------------------------------

/**
 * new fillup
 */
// set car id and fuel type for the fillup before the modal appears
$('#recordFillupModal').on('show.bs.modal', function(e) {
  var link = $(e.relatedTarget);
  $('#recordFillupModalCarName').html(link.data('car-name'));
  $('#recordFillupModalForm').attr('action', link.data('form-action'));
  $('#input-fuel_type_id').val(link.data('fuel-type'));
});

// set focus on mileage once the modal appears
$('#recordFillupModal').on('shown.bs.modal', function(e) {
  $('#input-mileage').trigger('focus');
});

/**
 * new service
 */
// set car id and fuel type for the fillup before the modal appears
$('#recordServiceModal').on('show.bs.modal', function(e) {
  var link = $(e.relatedTarget);
  $('#recordServiceModalCarName').html(link.data('car-name'));
  $('#recordServiceModalForm').attr('action', link.data('form-action'));
});

// set focus on mileage once the modal appears
$('#recordServiceModal').on('shown.bs.modal', function(e) {
  $('#input-mileage').trigger('focus');
});


// -----------------------------------
// Global App Functions
// -----------------------------------

/**
 * swaps two font-awesome icons
 * assumes both are the same icon type (far, fas, etc)
 *
 * @param ele The <i> tag or the parent element where to find the font awesome <i> tag
 * @param a One icon to swap
 * @param b The other icon swap
 */
export function swapIcons(ele, a, b) {
  const icon = $(ele).is('i') ? $(ele) : $(ele).find('i');
  if (icon.hasClass(a)) {
    icon.addClass(b).removeClass(a);
  } else {
    icon.addClass(a).removeClass(b);
  }
}

export function formatCurrencyNumber(input, decimals) {
  const num = parseFloat(input);

  if(isNaN(num)) {
      return (0).toFixed(decimals); //if the input is invalid just set the value to 0.000
  }

  // move the decimal up <decimals> places return a X.000 format
  return (num / Math.pow(10, decimals)).toFixed(decimals);
}
