import * as app from './app.js'

if (!$('#input-auto_replace').prop('checked')) {
  $('.retire-mileage-input').hide();
}

$("#input-auto_replace").on('change', function() {
  if ($(this).prop("checked")) {
    $('.retire-mileage-input').fadeIn();
    $('#autoReplaceHelpCol').addClass('col-6').removeClass('col-12');
  } else {
    $('.retire-mileage-input').fadeOut();
    $('#input-retire_mileage').val(null);
    $('#autoReplaceHelpCol').addClass('col-12').removeClass('col-6');
  }
});
