import * as app from './app.js'

// add driver typeahead
$.ajaxSetup({
  headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  }
});
$.typeahead({
  input: '#inputEmailSearch',
  order: 'asc',
  dynamic: true,
  hint: true,
  mustSelectItem: true,
  hightlight: true,
  template: '<span class="result_wrapper">' +
      '<span class="avatar">' +
        '<img src="{{photo_url}}">' +
      '</span>' +
      '<span class="name">{{first_name}} {{last_name}}</span>' +
      '<span class="email">{{email}}</span>' +
    '</span>',
  emptyTemplate: 'No results found for <strong>{{query}}</strong>',
  source: {
    user: {
      display: ['email'],
      ajax: [{
        type: 'post',
        url: '/users/search',
        data: {
          q: '{{query}}',
        },
        headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
      }, 'data.users']

    }
  },
  callback: {
    onClickAfter: function (node, a, item, event) {
      $('#inputUserId').val(item.id);
    }
  },
});
