// focus on mileage input box
$(window).on('load', function() {
  $('.page-gas #inputMileage').trigger('focus');
});

// Table sum/avg toggles
$('#gasHeaderShowAvg').on('change', function(e) {
  $('.header-avg').show();
  $('.header-sum').hide();
});
$('#gasHeaderShowSum').on('change', function(e) {
  $('.header-avg').hide();
  $('.header-sum').show();
});

/**
 * Datatables
 * display column visibility labels because we need to remove
 * the summary data and just show the title
 *
 * @param DataTable.api
 * @param integer
 * @param string
 * @return string
 */
function gasDataTablesColVisColumnTextFn(api, index, title) {
  const spaces = title.indexOf(' ');
  return (spaces > 0 ? title.substring(0, spaces) : title).trim();
}

/**
 * Datatables
 * after the table is set up, we need to make some adjustments
 * @param settings
 * @param json
 */
function gasDataTablesInitCompleteFn(settings, json) {
  // hide avg values in the table header
  $('.header-avg').hide();

  // move the avg/sum toggle to the datatables div
  $('#avgSumButtonGroup').prependTo($('#dtAvgSum'));

  // make the inputs input-md instead of input-sm so it lines up nicer with the buttons
  $('#gasTable_filter').find('input')
    .addClass('form-control-md').removeClass('form-control-sm');
  $('#gasTable_length').find('select')
    .addClass('form-control-md').removeClass('form-control-sm')
    .addClass('custom-select-md').removeClass('custom-select-sm');

  $('#gasTable_wrapper').find('.btn-secondary')
    .addClass('btn-outline-secondary').removeClass('btn-secondary');
}

// initialize the gas history datatable
$('#gasTable').dataTable({
    dom: '<"row" <"col-md-6 col-12"<"#dtAvgSum.float-left"><"float-left ml-2"l>><"col-md-6 col-12"<"float-md-right float-left"B><"float-md-right pr-2 float-left"f>>>rt<"row" <"col-md-6" <"float-left"i>><"col-md-6" <"float-right"p>>>',
    lengthMenu: [[10, 25, 100, -1], [10, 25, 100, "All"]],
    pageLength: 10,
    order: [[1, 'desc']], // order by mileage desc
    responsive: true,
    buttons: [
      {
        extend: 'colvis',
        columns: ':not(:first-child)',
        text: '<i class="fas fa-columns"></i>',
        columnText: gasDataTablesColVisColumnTextFn
      },
      {
        extend: 'excelHtml5',
        text: '<i class="fas fa-download"></i>'
      },
    ],
    language: {
      search: '',
      searchPlaceholder: 'Filter table',
    },
    orderCellsTop: true,
    initComplete: gasDataTablesInitCompleteFn,
});
